var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("inventory.blank")))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","small":"","color":"success"},on:{"click":function($event){_vm.getInventoryExcel(1);
          _vm.items_excel = [];}}},[_vm._v(" Excelga yuklab olish ")])],1),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","server-items-length":_vm.server_items_length,"options":_vm.dataTableOptions,"disable-pagination":true,"footer-props":{
        itemsPerPageOptions: [50, 100, 200],
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return undefined},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"red","small":"","text":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.downloadExcel),callback:function ($$v) {_vm.downloadExcel=$$v},expression:"downloadExcel"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-1 px-3"},[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.downloadExcel = false}}},[_c('download-excel',{attrs:{"data":_vm.items_excel,"name":'Inv_blank_' + _vm.today + '.xls'}},[_c('span',{staticStyle:{"color":"#4caf50"}},[_vm._v(_vm._s(_vm.$t("download")))]),_c('v-icon',{attrs:{"color":"success","height":"20"}},[_vm._v("mdi-download")])],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","icon":""},on:{"click":function($event){_vm.downloadExcel = false}}},[_c('v-icon',{attrs:{"color":"error","height":"20"}},[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("loadingText"))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }